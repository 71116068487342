import './DesktopHeader.scss';

import { b2x } from '@b2x/react/src';
import React from 'react';

import { useAppContext, useAppStaticContext } from './AppContext';
import { HeaderContentType } from './contentTypes';
import { DesktopHeader } from './DesktopHeader';
import logo from './images/logo-desktop.svg';
import logoSmall from './images/logo-mobile.svg';
import { MobileHeader } from './MobileHeader';

export interface HeaderProps {}

export const Header = (props: HeaderProps) => {
  const content = b2x.useContent<HeaderContentType>('HEADER_CONTENT');
  const mobileHeaderRef = React.useRef<HTMLDivElement>(null);
  const desktopHeaderSecondRowRef = React.useRef<HTMLDivElement>(null);

  const { headerFirstRowRef } = useAppContext();
  const { headerCheckout } = useAppContext();
  const { setHeaderHeight } = useAppStaticContext();

  const { width } = b2x.useWindowSize();

  const recalculateHeaderHeight = React.useCallback(() => {
    const currentBreakpoint = b2x.getCurrentBreakpoint();
    if (['xs', 'sm', 'md'].includes(currentBreakpoint)) {
      // Mobile
      if (mobileHeaderRef.current) {
        setHeaderHeight(mobileHeaderRef.current.clientHeight);
      }
    } else {
      // Desktop
      if (headerFirstRowRef.current && desktopHeaderSecondRowRef.current) {
        setHeaderHeight(headerFirstRowRef.current.clientHeight + desktopHeaderSecondRowRef.current.clientHeight);
      }
    }
  }, [headerFirstRowRef, desktopHeaderSecondRowRef, mobileHeaderRef, setHeaderHeight]);

  React.useEffect(() => {
    recalculateHeaderHeight();
  }, [recalculateHeaderHeight, width]);

  return (
    <>
      {!headerCheckout ? (
        <>
          <MobileHeader
            content={content}
            innerRef={mobileHeaderRef}
            recalculateHeaderHeight={recalculateHeaderHeight}
            visibleUntil="lg"
          />
          <DesktopHeader
            content={content}
            recalculateHeaderHeight={recalculateHeaderHeight}
            secondRowRef={desktopHeaderSecondRowRef}
            visibleFrom="lg"
          />
        </>
      ) : (
        <b2x.HeaderCheckout
          desktopCenterItemStyle="h5 m-0"
          desktopRightItemStyle="extra-small text-dark text-uppercase"
          isSticky
          logo={logo}
          logoSmall={logoSmall}
          mobileCenterItemStyle="extra-small fw-light text-dark text-uppercase mb-3"
          mobileFrom={'md'}
          recalculateHeaderHeight={recalculateHeaderHeight}
          wrapperStyle="shadow bg-white py-xl-3"
        />
      )}
    </>
  );
};
