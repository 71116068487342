import './ProductCategorySlider.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';
import { SwiperSlide } from 'swiper/react';

import { ProductCategorySection } from '../contentTypes';
import { Section } from '../Section';
import { Slider } from './Slider';
import { SliderButton } from './SliderButton';

export interface ProductCategorySliderProps extends ProductCategorySection {}

export const ProductCategorySlider = ({ itemList, title }: ProductCategorySliderProps) => {
  const sliderNextButton = React.useRef<HTMLButtonElement>(null);
  const sliderPrevButton = React.useRef<HTMLButtonElement>(null);
  return (
    <Section className="product-category-slider bg-gray-100 py-5" noMarginY title={title}>
      {itemList && (
        <div className="row">
          <div className="col-12 pe-0 pe-xl-3">
            <div className="slider-container position-relative">
              <Slider
                navigation
                responsive={{
                  lg: {
                    slidesPerView: 3.4,
                  },
                  sm: {
                    slidesPerView: 2.4,
                  },
                  xl: {
                    slidesPerView: 4,
                  },
                }}
                sliderNextEl={sliderNextButton}
                sliderPrevEl={sliderPrevButton}
                slidesPerView={1.4}
                spaceBetween={20}
              >
                {itemList.map((item, index) => (
                  <SwiperSlide key={item.contentSectionId}>
                    <div className="category-slide text-center">
                      {item.cta ? (
                        <b2x.DeprecatedCta cta={item.cta}>
                          <b2x.ImageFromContentV1 {...item.img} fluid />
                        </b2x.DeprecatedCta>
                      ) : (
                        <b2x.ImageFromContentV1 {...item.img} fluid />
                      )}
                      {item.imgTitle && <h4 className="text-blue fw-semi-bold mb-2 mt-4">{item.imgTitle}</h4>}
                      {item.cta && item.cta.label && (
                        <b2x.DeprecatedCta
                          className={classnames(item.cta.variant, 'text-secondary text-uppercase small')}
                          cta={item.cta}
                        >
                          {b2x.formatHtml(item.cta.label)}
                        </b2x.DeprecatedCta>
                      )}
                    </div>
                  </SwiperSlide>
                ))}
              </Slider>
              <SliderButton direction="left" innerRef={sliderPrevButton} size="small" />
              <SliderButton direction="right" innerRef={sliderNextButton} size="small" />
            </div>
          </div>
        </div>
      )}
    </Section>
  );
};
