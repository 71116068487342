import './ProductTile.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { t } from './i18n/i18n';

export type ProductTileProps = b2x.ProductTileProps;

export const ProductTile = (props: ProductTileProps) => {
  return <b2x.ProductTile className={'card border-0 p-3 p-md-4'} {...props} />;
};

export type ProductTileBasicInfoProps = b2x.ProductTileBasicInfoProps;

export const ProductTileBasicInfo = ({
  handleLinkClick,
  imageFormat,
  product,
  productsPerRow,
  sku,
}: ProductTileBasicInfoProps) => {
  const { bestPriceDiscountPercentage, bestPriceValue, crossedOutPrice, discountPercentage, isPriceInRange, price } =
    b2x.usePrice(product.priceRange, sku?.price);

  return (
    <div className="product-tile-basic-info">
      <div className="product-tile-image-container position-relative">
        <b2x.router.Link onClick={handleLinkClick} style={{ display: 'block' }} to={product.url}>
          <b2x.Image
            alt={product.name}
            aspectRatio={b2x.appConfig.productImageAspectRatio}
            fluid
            format={imageFormat}
            src={product.image?.src}
          />
        </b2x.router.Link>
        {discountPercentage !== undefined && discountPercentage > 0 && (
          <div className="position-absolute top-0 left-0">
            <div className="discount-percentage bg-promo text-white small lh-1 fw-semi-bold p-1 rounded-1">{`-${b2x.formatFloatPercentage(
              discountPercentage
            )}%`}</div>
          </div>
        )}
      </div>
      <b2x.EqualHeightElement name={`product-title-row-${productsPerRow}`}>
        <h6>
          <b2x.router.Link className="text-decoration-none text-black" onClick={handleLinkClick} to={product.url}>
            {b2x.formatHtml(product.name)}
          </b2x.router.Link>
        </h6>
      </b2x.EqualHeightElement>
      <div className="product-tile-price">
        <b2x.PriceBlock
          gap={2}
          priceHelper={{ crossedOutPrice: crossedOutPrice, isPriceInRange: isPriceInRange, price: price }}
        />
        {b2x.appConfig.enableBestPrice && (
          <div className="extra-small lh-1">
            <b2x.BestPrice
              priceHelper={{
                bestPriceDiscountPercentage: bestPriceDiscountPercentage,
                bestPriceValue: bestPriceValue,
                crossedOutPrice: crossedOutPrice,
                price: price,
              }}
            />
          </div>
        )}
      </div>
      <div className="text-center d-grid d-md-block">
        <b2x.router.Link className="btn btn-primary px-0 px-md-3 my-3" onClick={handleLinkClick} to={product.url}>
          {t('misc.discoverMore')}
        </b2x.router.Link>
      </div>
    </div>
  );
};

export type ProductTileExtraInfoProps = b2x.ProductTileExtraInfoProps;

export const ProductTileExtraInfo = (props: ProductTileExtraInfoProps) => (
  <b2x.ProductTileExtraInfo customComponent={CustomProductTileExtraInfo} {...props} />
);

const CustomProductTileExtraInfo = ({ fieldsHelper, productsPerRow }: ProductTileExtraInfoProps) => {
  return (
    <div className="product-tile-extra-info mt-3">
      <b2x.EqualHeightElement name={`product-extra-info-row-${productsPerRow}`}>
        <div>
          <b2x.FormGroup
            {...fieldsHelper.productVariants.formGroup}
            className="product-variant bg-gray-100 pt-2 px-2 d-inline-block"
            label={undefined}
          >
            <div>
              {/* <div className="mb-1">{t('form.productForm.size')}</div> */}
              <div className="d-flex flex-wrap lh-1">
                {fieldsHelper.productVariants.formFields.map((formField, index) => (
                  <b2x.Radio
                    key={formField.productVariant.id}
                    {...formField.radio}
                    className="m-0"
                    inline
                    labelClassName={classnames(
                      { 'me-2': fieldsHelper.productVariants.formFields.length !== index + 1 },
                      'mb-2 fw-bold shadow-sm px-2 py-1'
                    )}
                  >
                    {formField.productVariant.skus?.map((sku, indexSku) => indexSku === 0 && sku.name)}
                  </b2x.Radio>
                ))}
              </div>
            </div>
          </b2x.FormGroup>
        </div>
      </b2x.EqualHeightElement>
      {/* <b2x.Button {...fieldsHelper.buttons.submit} /> */}
    </div>
  );
};
