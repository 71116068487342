import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { MainProductSection } from './contentTypes';

export interface ContentRowAProps extends MainProductSection {
  fullRow: boolean;
}

export const ContentRowA = ({ content, cta, fullRow = false, img, title }: ContentRowAProps) => {
  return (
    <div
      className={classnames(
        'my-3',
        fullRow ? 'container-fluid container-fluid-w-custom my-lg-0' : 'container-xxl my-lg-5'
      )}
    >
      <b2x.Row>
        <b2x.Col className={classnames('text-center', { 'p-lg-0': fullRow })} size={{ lg: 6, xs: 12 }}>
          {img && <b2x.ImageFromContentV1 {...img} className="mb-lg-0 mb-3" fluid />}
        </b2x.Col>
        <b2x.Col
          className={classnames(fullRow ? 'd-lg-flex align-items-center p-lg-5' : 'offset-xl-1')}
          size={{ lg: 6, xl: 5, xs: 12 }}
        >
          <div className={classnames('text-center text-lg-start', fullRow ? 'ps-xl-5' : '')}>
            {title && <div className="display-2 mb-lg-4 mb-3 text-blue">{b2x.formatHtml(title)}</div>}
            {content && <div className="mb-lg-5 mb-4">{b2x.formatHtml(content)}</div>}
            {cta && cta.label && (
              <b2x.DeprecatedCta className={classnames('btn text-white', `btn-${cta.variant}`)} cta={cta}>
                {b2x.formatHtml(cta.label)}
              </b2x.DeprecatedCta>
            )}
          </div>
        </b2x.Col>
      </b2x.Row>
    </div>
  );
};
